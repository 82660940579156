import { RefObject, useMemo, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "next-i18next";

import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";
import { InputSearchProps } from "@sellernote/_sds-v2/src/components/form/InputSearch";
import PlaneIcon from "@sellernote/_sds-v2/src/components/svgIcons/PlaneIcon";
import Ship3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Ship3Icon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import {
  createSchedulePortOption,
  getPortType,
  getRecommendCategoryOptionList,
} from "../utils";

import InputSearchWithPortOptions, {
  PortInfoType,
} from "../../../components/InputSearchWithPortOptions";
import PortOptionWithCategory from "../../../components/InputSearchWithPortOptions/components/PortOptionWithCategory";
import useGetPortOptions from "../../../components/InputSearchWithPortOptions/hooks/useGetPortOptions";
import { FocusInputHandler } from "../../../components/InputSearchWithPortOptions/types";
import {
  checkIsWarehouse,
  getPortLabel,
} from "../../../components/InputSearchWithPortOptions/utils";

import {
  FORWARDING_SCHEDULE_ACTIONS,
  FORWARDING_SCHEDULE_SELECTORS,
} from "../../../jotaiStates/forwarding/schedule";
import { SCHEDULE_PORT_CATEGORY_INFO_LIST } from "../constants";
import { useSearch } from "../SearchContext";

interface Props {
  isMobile?: boolean;
  polOptionHandlerListRef: RefObject<FocusInputHandler>;
}

export default function Pol({
  isMobile = false,
  polOptionHandlerListRef,
}: Props) {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { t } = useTranslation();

  const [{ freightType, polId, podId }, setSearch] = useSearch();

  const { selectedPol: localSelectedPol, selectedPod } = useAtomValue(
    FORWARDING_SCHEDULE_SELECTORS.SELECTED_PORT_INFO
  );

  const updateSelectedPortInfo = useSetAtom(
    FORWARDING_SCHEDULE_ACTIONS.UPDATE_SELECTED_PORT_INFO
  );

  // 도착지를 한국이 아닌 국가를 선택한 경우 출발지는 한국 국가만 노출
  const country = (() => {
    if (!selectedPod) return undefined;

    return selectedPod.country !== "KR" ? "KR" : undefined;
  })();

  // 도착지에서 선택한 국가는 옵션에서 제외시킴
  const exceptCountry = (() => {
    if (selectedPod?.country) return [selectedPod?.country];

    return undefined;
  })();

  const {
    rawPortList,
    portOptionList,
    isLoading: isLoadingPortOptionList,
  } = useGetPortOptions({
    pointType: "start",
    portType: getPortType(freightType),
    searchTerm: debouncedSearchTerm,
    categoryInfoList: SCHEDULE_PORT_CATEGORY_INFO_LIST,
    transformToPortOption: createSchedulePortOption,
    ...(!polId &&
      selectedPod && {
        country,
        exceptCountry,
      }),
  });

  /**
   * useSearch hook에 selectedPol 값이 있지만 queryString을 반영하기 위해 API 호출로 값을 가져옴
   */
  const selectedPol = useMemo(() => {
    if (!polId) return undefined;

    if (localSelectedPol) return localSelectedPol;

    return rawPortList.find((port) => port.id === polId);
  }, [polId, rawPortList, localSelectedPol]);

  const polRecommendationList = useMemo(() => {
    if (debouncedSearchTerm) return [];

    return getRecommendCategoryOptionList({
      portList: rawPortList,
      freightType,
      locationType: "pol",
      portCountryOfAnotherLocationType: rawPortList?.find(
        (port) => port.id === podId
      )?.country,
      t,
    });
  }, [debouncedSearchTerm, rawPortList, freightType, t, podId]);

  const handlePolSearchTermChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: searchTerm } = e.target;

    setSearchTerm(searchTerm);
  };

  const handlePolSelect = (portInfo: PortInfoType) => {
    if (checkIsWarehouse(portInfo) || !portInfo) return;

    const { id: polId, portGroupId: polPortGroupId } = portInfo;

    setSearch({
      polId,
      polPortGroupId,
    });

    updateSelectedPortInfo({
      type: "pol",
      portInfo,
    });
  };

  const handlePolReset = () => {
    setSearch({ polId: null, polPortGroupId: null });

    updateSelectedPortInfo({
      type: "pol",
      portInfo: undefined,
    });

    setSearchTerm("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isBackspaceKey = e.key === "Backspace";

    if (!selectedPol || !isBackspaceKey) return;

    handlePolReset();
  };

  const isLoading = (() => {
    if (!searchTerm || Boolean(selectedPol)) return false;

    return searchTerm !== debouncedSearchTerm || isLoadingPortOptionList;
  })();

  const inputValue = (() => {
    if (!selectedPol) return searchTerm;

    return getPortLabel(selectedPol);
  })();

  const leftIconInfo: InputSearchProps["leftIconInfo"] = (() => {
    if (!selectedPol) return undefined;

    return {
      color: COLOR.bk_80,
      Icon: selectedPol.type === "sea" ? Ship3Icon : PlaneIcon,
    };
  })();

  const searchSourceList = (() => {
    if (selectedPol) return [];

    if (!searchTerm) return polRecommendationList;

    return portOptionList;
  })();

  return (
    <InputSearchWithPortOptions
      className="custom-label-color"
      {...(!isMobile && { width: 296 })}
      labelInfo={{
        label: (
          <>
            {t("page-forwarding-schedule:출발지")}
            <span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      searchSourceList={searchSourceList}
      renderPanel={({ option, ...optionProps }) => (
        <PortOptionWithCategory
          key={option.category.label}
          optionInfo={option}
          {...optionProps}
        />
      )}
      leftIconInfo={leftIconInfo}
      placeholder={t("page-forwarding-schedule:항구/공항을_입력해주세요.")}
      onChange={handlePolSearchTermChange}
      isLoading={isLoading}
      searchTerm={inputValue}
      onSelect={handlePolSelect}
      onReset={handlePolReset}
      onKeyDown={handleKeyDown}
      focusHandlerRef={polOptionHandlerListRef}
    />
  );
}
