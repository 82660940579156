import { useRef } from "react";

import { FocusInputHandler } from "../../../components/InputSearchWithPortOptions/types";
import Eta from "../components/Eta";
import Etd from "../components/Etd";
import Pod from "../components/Pod";
import Pol from "../components/Pol";
import Search from "../components/Search";

import { SearchProvider } from "../SearchContext";
import FreightType from "./FreightType";
import Styled from "./index.styles";

export default function ScheduleSearchBarForMobile() {
  const polOptionHandlerListRef = useRef<FocusInputHandler>(null);

  const podOptionHandlerListRef = useRef<FocusInputHandler>(null);

  return (
    <SearchProvider>
      <Styled.searchBar className="schedule-search-bar-for-mobile">
        <Styled.searchConditionList>
          <FreightType />

          <Pol isMobile polOptionHandlerListRef={polOptionHandlerListRef} />

          <Pod isMobile podOptionHandlerListRef={podOptionHandlerListRef} />

          <Styled.partContainer>
            <Etd isMobile />

            <Eta isMobile />
          </Styled.partContainer>
        </Styled.searchConditionList>

        <Search
          isMobile
          polOptionHandlerListRef={polOptionHandlerListRef}
          podOptionHandlerListRef={podOptionHandlerListRef}
        />
      </Styled.searchBar>
    </SearchProvider>
  );
}
