import { useRef } from "react";

import { FocusInputHandler } from "../../../components/InputSearchWithPortOptions/types";
import Eta from "../components/Eta";
import Etd from "../components/Etd";
import Pod from "../components/Pod";
import Pol from "../components/Pol";
import Search from "../components/Search";

import { SearchProvider } from "../SearchContext";
import FreightType from "./FreightType";
import Styled from "./index.styles";

export default function ScheduleSearchBarForDesktop({
  isLanding = false,
}: {
  isLanding?: boolean;
}) {
  const polOptionHandlerListRef = useRef<FocusInputHandler>(null);

  const podOptionHandlerListRef = useRef<FocusInputHandler>(null);

  return (
    <SearchProvider>
      <Styled.searchBar
        isLanding={isLanding}
        className="schedule-search-bar-for-desktop"
      >
        <FreightType />

        <Styled.partContainer>
          <Pol polOptionHandlerListRef={polOptionHandlerListRef} />

          <Pod podOptionHandlerListRef={podOptionHandlerListRef} />
        </Styled.partContainer>

        <Styled.partContainer>
          <Etd />

          <Eta />
        </Styled.partContainer>

        <Search
          polOptionHandlerListRef={polOptionHandlerListRef}
          podOptionHandlerListRef={podOptionHandlerListRef}
        />
      </Styled.searchBar>
    </SearchProvider>
  );
}
